/** @name 机位倾斜 */
export const MOCK_STAND_TILT = 'drawer-stand-tilt';
/** @name 机位载荷 */
export const MOCK_STAND_PULL = 'drawer-stand-pull';
/** @name 总控箱电流 */
export const MOCK_MAIN_CONTROL_BOX_CURRENT = 'drawer-main-control-box-current';
/** @name 机位电池状态 */
export const MOCK_STAND_BATTERY_STATUS = 'drawer-stand-battery-status';
/** @name 机位电池电量 */
export const MOCK_BATTERY_CAPACITY_OF_STAND =
  'drawer-battery-capacity-of-stand';

<template>
  <div :class="$style.card">
    <div :class="$style.title">
      <span :class="$style.dot"></span> {{ title }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CardInfo extends Vue {
  @Prop({ type: String, default: '' }) title;
}
</script>

<style lang="less" module>
.card {
}
.title {
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: var(--font);
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  padding: 20px 0;
}
.dot {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  display: block;
  margin-right: 10px;
  border-radius: 50%;
  border: 4px solid var(--font-active-light);
  box-shadow: 0px 1px 4px 1px var(--font-active-light);
}
</style>

<template>
  <div :class="$style.wrap">
    <i class="TC tc-icon-jinggao" :class="$style.icon"></i>
    <slot />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class ConfirmMessage extends Vue {}
</script>
<style lang="less" module>
.wrap {
  text-align: center;

  .icon {
    font-size: 18px;
    margin-right: 10px;
    vertical-align: middle;
  }
}
</style>

<template>
  <a-card :bordered="false" :class="$style.cardWrap">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="inline">
      <a-form-model-item
        :label="$t('electricBoxMock.form.projectId')"
        prop="projectId"
      >
        <a-select
          v-model="form.projectId"
          @change="handleSelectProjectChange"
          showSearch
          :filterOption="filterOption"
          style="width: 380px"
          :options="projectArray.slice(0, loadingCount)"
          :placeholder="$t('electricBoxMock.placeholder.projectId')"
          @focus="focus"
          @blur="blur"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('electricBoxMock.form.buildingId')"
        prop="buildingId"
      >
        <a-select
          v-model="form.buildingId"
          style="width: 380px"
          :placeholder="$t('electricBoxMock.placeholder.buildingId')"
          @change="handleSelectBuildingChange"
        >
          <a-select-option
            :value="item.pkBuildingId"
            v-for="item in buildArray"
            :key="item.pkBuildingId"
          >
            {{ item.buildingName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('electricBoxMock.form.mock')">
        <a-switch
          v-model="form.mockSwitch"
          :checked-children="$t('electricBoxMock.form.open')"
          :un-checked-children="$t('electricBoxMock.form.close')"
          @change="mockChange"
        />
      </a-form-model-item>
    </a-form-model>
    <div :class="$style.btnWrap">
      <BtnInfo
        :item="item"
        v-for="(item, index) in buttonGroup"
        :class="validate ? '' : $style.validate"
        :key="index"
      />
    </div>
  </a-card>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { message } from 'ant-design-vue';
import { createDrawer, createFormModal } from '@triascloud/x-components';
import { crossStorageModule, globalModule } from '@/enum/store.js';
import BtnInfo from './components/btn-info';
import DrawerInfo from './components/drawer-info.vue';
import { submitSimulate } from '@/services/iot-electric-box/simulate.js';
import {
  getProjectAndBuilding,
  getProjectByCompanyId,
} from '@/services/things/project.js';
import ConfirmMessage from './components/confirm-message.vue';
import {
  MOCK_STAND_TILT,
  MOCK_STAND_PULL,
  MOCK_MAIN_CONTROL_BOX_CURRENT,
  MOCK_STAND_BATTERY_STATUS,
  MOCK_BATTERY_CAPACITY_OF_STAND,
} from './components/mock-types.js';
import { addSimulation } from '@/services/iot-electric-box/simulation';
import { updateOpenSimulationData } from '@/services/things/iot-authority';
import Tip from '@/components/tip/index.vue';

@Component({
  components: {
    BtnInfo,
  },
})
export default class MockForm extends Vue {
  @crossStorageModule.State('user') currentUser;
  @globalModule.State mock;
  @globalModule.Action fetchMockStatus;
  validate = false;
  get i18n() {
    return this.$t('electricBoxMock');
  }
  created() {
    this.getProjectByCompanyIdApi();
  }
  async handleText() {
    await createFormModal(
      () => () => (
        <Tip>
          <template slot="txt">
            您确定要重置安监终端的管理密码么，请确认！
          </template>
          <template slot="subTxt">
            注：重置后请用手机登录三叠云移动端，然后在移动端的物联网模块中查看相应的管理密码？
          </template>
        </Tip>
      ),
      {
        title: '提示',
      },
    );
  }

  async mockChange(data) {
    await updateOpenSimulationData({
      turnOn: data,
    });
    await this.fetchMockStatus();
  }

  @Watch('mock', { immediate: true })
  onMockChange(value) {
    this.form.mockSwitch = value;
  }

  rules = {
    projectId: [
      {
        required: true,
        message: this.$t('electricBoxMock.placeholder.projectId'),
        trigger: ['change', 'blur'],
      },
    ],
    buildingId: [
      {
        required: true,
        message: this.$t('electricBoxMock.placeholder.buildingId'),
        trigger: ['change', 'blur'],
      },
    ],
  };
  form = {
    projectId: '',
    buildingId: '',
    mockSwitch: false,
  };
  projectId = '';
  buildingId = '';
  projectArray = [];
  loadingCount = 200;
  delayHand = null;
  focus() {
    this.delaySelectLoad();
  }
  delaySelectLoad() {
    if (this.projectArray.length <= this.loadingCount) return;
    this.loadingCount += 100;
    this.delayHand = setTimeout(this.delaySelectLoad, 666);
  }
  blur() {
    this.delayHand && clearTimeout(this.delayHand);
    this.loadingCount = 200;
  }
  buildArray = [];
  validateData() {
    return new Promise(resolve => {
      this.$refs.ruleForm.validate(valid => resolve(valid));
    });
  }
  /** @name 项目列表 */
  async getProjectByCompanyIdApi() {
    const result = await getProjectByCompanyId();
    this.projectArray = result.map(e => ({
      title: e.projectShort,
      value: e.pkProjectId,
    }));
  }
  filterOption(val, option) {
    const title = option.data.props.title;
    return title.indexOf(val) !== -1;
  }
  handleSelectProjectChange(projectId) {
    this.projectId = projectId;
    this.form.buildingId = '';
    this.validate = false;
    this.buildArray = [];
    this.getProjectAndBuildingApi(projectId);
  }
  /** @name 楼栋列表 */
  async getProjectAndBuildingApi(projectId) {
    const result = await getProjectAndBuilding(projectId);
    this.buildArray = result.buildings;
  }
  handleSelectBuildingChange(buildingId) {
    this.buildingId = buildingId;
    this.validate = !!buildingId;
  }
  /** @name 智能数据模拟 */
  async submitSimulateApi(data, options) {
    try {
      const result = await submitSimulate(data);
      options.success && options.success(result);
    } catch (error) {
      options.error && options.error(error);
    }
  }
  /** @name 安全防护平台提升弹窗确认 */
  async handleClimbingFrameLifting() {
    const resultProject = this.projectArray.find(
      v => `${v.value}` === this.form.projectId,
    );
    const resultBuilding = this.buildArray.find(
      v => `${v.pkBuildingId}` === this.form.buildingId,
    );
    const SIMULATE_TYPE = 'CLIM_RISE';
    const validateResult = await this.validateData();
    if (!validateResult) return false;
    await createFormModal(
      () => (
        <ConfirmMessage>
          {this.$t('electricBoxMock.message.confirm') +
            resultProject.title +
            this.$t('electricBoxMock.message.confirm1')}
        </ConfirmMessage>
      ),
      { title: '提示' },
    );
    const data = {
      buildId: this.buildingId,
      simulateType: SIMULATE_TYPE,
      type: 'MANUAL',
    };

    this.submitSimulateApi(data, {
      success: () => {
        message.success({
          text: this.$t('electricBoxMock.message.sucess'),
        });
        addSimulation({
          operationTime: Date.now(),
          operator: this.currentUser.nickname,
          simulationType: SIMULATE_TYPE,
          simulationValue: '1',
          projectName: resultProject.title,
          buildingName: resultBuilding.buildingName,
          equipment: '全部',
        }).then(() => {
          this.$emit('submit');
        });
      },
      error: error => {
        message.error(
          error.message || this.$t('electricBoxMock.message.error'),
        );
      },
    });
  }
  /** @name 安全防护平台下降弹窗确认 */
  async handleClimbingFrameDescending() {
    const resultProject = this.projectArray.find(
      v => `${v.value}` === this.form.projectId,
    );
    const resultBuilding = this.buildArray.find(
      v => `${v.pkBuildingId}` === this.form.buildingId,
    );
    const validateResult = await this.validateData();
    if (!validateResult) return false;
    await createFormModal(
      () => (
        <ConfirmMessage>
          {this.$t('electricBoxMock.message.downConfirm') +
            resultProject.title +
            this.$t('electricBoxMock.message.downConfirm1')}
        </ConfirmMessage>
      ),
      { title: '提示' },
    );
    const SIMULATE_TYPE = 'CLIM_DROP';
    const data = {
      buildId: this.buildingId,
      simulateType: SIMULATE_TYPE,
      type: 'MANUAL',
    };
    this.submitSimulateApi(data, {
      success: () => {
        message.success({
          text: this.$t('electricBoxMock.message.downSucess'),
        });
        addSimulation({
          operationTime: Date.now(),
          operator: this.currentUser.nickname,
          simulationType: SIMULATE_TYPE,
          simulationValue: '1',
          projectName: resultProject.title,
          buildingName: resultBuilding.buildingName,
          equipment: '全部',
        }).then(() => {
          this.$emit('submit');
        });
      },
      error: error => {
        message.error(
          error.message || this.$('electricBoxMock.message.downError'),
        );
      },
    });
  }
  get buttonGroup() {
    return [
      {
        backgroundColor: '#01B893',
        imgUrl: require('@/assets/images/electric-box/arrow-up.png'),
        text: this.$t('electricBoxMock.form.promote'),
        disabled: false,
        clickHandle: () => {
          this.handleClimbingFrameLifting();
        },
      },
      {
        backgroundColor: '#947EFA',
        imgUrl: require('@/assets/images/electric-box/arrow-down.png'),
        text: this.$t('electricBoxMock.form.down'),
        disabled: false,
        clickHandle: () => {
          this.handleClimbingFrameDescending();
        },
      },
      {
        backgroundColor: '#425FEE',
        imgUrl: require('@/assets/images/electric-box/lean.png'),
        text: this.$t('electricBoxMock.form.lean'),
        disabled: false,
        clickHandle: () => {
          this.handleDrawer({
            type: MOCK_STAND_TILT,
            title: this.$t('electricBoxMock.title.lean'),
          });
        },
      },
      {
        backgroundColor: '#FF9933',
        imgUrl: require('@/assets/images/electric-box/pull.png'),
        text: this.$t('electricBoxMock.form.pull'),
        disabled: false,
        clickHandle: () => {
          this.handleDrawer({
            type: MOCK_STAND_PULL,
            title: this.$t('electricBoxMock.title.pull'),
          });
        },
      },
      {
        backgroundColor: '#F06140',
        imgUrl: require('@/assets/images/electric-box/electric.png'),
        text: this.$t('electricBoxMock.form.electric'),
        disabled: false,
        clickHandle: () => {
          this.handleDrawer({
            type: MOCK_MAIN_CONTROL_BOX_CURRENT,
            title: this.$t('electricBoxMock.title.electric'),
          });
        },
      },
      {
        backgroundColor: '#2199F6',
        imgUrl: require('@/assets/images/electric-box/battery.png'),
        text: this.$t('electricBoxMock.form.battery'),
        disabled: false,
        clickHandle: () => {
          this.handleDrawer({
            type: MOCK_STAND_BATTERY_STATUS,
            title: this.$t('electricBoxMock.title.battery'),
          });
        },
      },
      {
        backgroundColor: '#00A4A4',
        imgUrl: require('@/assets/images/electric-box/electric-quantity.png'),
        text: this.$t('electricBoxMock.form.quantity'),
        disabled: false,
        clickHandle: () => {
          this.handleDrawer({
            type: MOCK_BATTERY_CAPACITY_OF_STAND,
            title: this.$t('electricBoxMock.title.quantity'),
          });
        },
      },
    ];
  }
  drawerInstance = null;
  async handleDrawer(options) {
    const validateResult = await this.validateData();
    if (!validateResult) return false;
    const resultProject = this.projectArray.find(
      v => `${v.value}` === this.form.projectId,
    );
    const resultBuilding = this.buildArray.find(
      v => `${v.pkBuildingId}` === this.form.buildingId,
    );
    const result = {
      projectName: resultProject.title,
      buildingName: resultBuilding.buildingName,
    };
    const { type, title } = options;
    const resultDrawer = createDrawer(
      () => (
        <DrawerInfo
          onConfirm={this.handleSubmit}
          type={type}
          options={{ ...result, buildId: this.buildingId }}
          submitSimulateApi={this.submitSimulateApi}
          close={this.handleCloseDrawer}
        />
      ),
      {
        title,
        width: '590px',
      },
    );
    this.drawerInstance = resultDrawer;
  }
  handleCloseDrawer() {
    this.drawerInstance.handleClosed();
  }
  handleSubmit(result) {
    this.drawerInstance && this.handleCloseDrawer();
    if (this.drawerInstance) {
      let mockValue = '';
      if (Array.isArray(result.data)) {
        result.data.forEach(async item => {
          await addSimulation({
            operationTime: Date.now(),
            operator: this.currentUser.nickname,
            simulationType: result.type,
            simulationValue: item.value,
            projectName: result.projectName,
            buildingName: result.buildingName,
            equipment: item.cId,
          }).then(() => {
            this.$emit('submit');
          });
        });
      }
      if (result.mainbox) {
        mockValue = '总控箱';
        addSimulation({
          operationTime: Date.now(),
          operator: this.currentUser.nickname,
          simulationType: result.type,
          simulationValue: result.data,
          projectName: result.projectName,
          buildingName: result.buildingName,
          equipment: mockValue,
        }).then(() => {
          this.$emit('submit');
        });
      }
      if (result.mock) {
        addSimulation({
          operationTime: Date.now(),
          operator: this.currentUser.nickname,
          simulationType: result.type,
          simulationValue: '随机（一键模拟）',
          projectName: result.projectName,
          buildingName: result.buildingName,
          equipment: '随机（一键模拟）',
        }).then(() => {
          this.$emit('submit');
        });
      }
    } else {
      this.$emit('submit');
    }
  }
}
</script>
<style lang="less" module>
.cardWrap {
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(203, 203, 203, 0.4);
  :global(.ant-form-item-label) {
    margin-right: 20px;
  }
  :global(.ant-form-item-with-help) {
    margin-bottom: 0;
  }
  :global(.ant-row) {
    margin-bottom: 20px;
  }
}
.btnWrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
}
.validate::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  position: absolute;
  top: 0;
  z-index: 100;
  left: 0;
}
</style>

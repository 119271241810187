import request from '../request';

const serviceName = '/iot-electric-box';

/**
 * @name 模拟下发数据
 * @param { Object } data
 */
export function submitSimulate(data) {
  return request(`${serviceName}/api/simulate`, {
    method: 'POST',
    body: data,
    closeMessage: true,
  });
}

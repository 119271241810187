<template>
  <component
    :is="type"
    :options="options"
    :submitSimulateApi="submitSimulateApi"
    :close="close"
    @confirm="handleConfirm"
  />
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import DrawerStandTilt from './drawer-stand-tilt.vue';
import DrawerStandPull from './drawer-stand-pull.vue';
import DrawerMainControlBoxCurrent from './drawer-main-control-box-current.vue';
import DrawerStandBatteryStatus from './drawer-stand-battery-status';
import DrawerBatteryCapacityOfStand from './drawer-battery-capacity-of-stand.vue';

function noop() {}

@Component({
  components: {
    DrawerStandTilt,
    DrawerStandPull,
    DrawerMainControlBoxCurrent,
    DrawerStandBatteryStatus,
    DrawerBatteryCapacityOfStand,
  },
})
export default class DrawerInfo extends Vue {
  @Prop({ type: String, default: '' }) type;
  @Prop({ type: Object, default: () => {} }) options;
  @Prop({ type: Function, default: noop }) submitSimulateApi;
  @Prop({ type: Function, default: noop }) close;

  handleConfirm(data) {
    this.$emit('confirm', data);
  }
}
</script>

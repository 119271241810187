<template>
  <a-select
    v-model="statusName"
    style="width: 175px;"
    @change="handleChange"
    placeholder="请选择状态"
  >
    <a-select-option
      :value="item.value"
      v-for="(item, index) in array"
      :key="'index' + index"
    >
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class SelectCell extends Vue {
  statusName = this.text;
  @Prop({ type: String, default: '' }) text;
  array = [
    {
      name: '异常',
      value: '-1',
    },
    {
      name: '正常',
      value: '1',
    },
  ];

  handleChange(value) {
    this.$emit('change', value);
  }
}
</script>

<template>
  <a-input
    :class="$style.input"
    :value="value"
    @input="handleChange"
    :decimalSeparator="2"
  />
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { numberRegular } from '@/assets/js/util.js';

@Component
export default class EditableCell extends Vue {
  value = this.text;

  @Prop({ type: String, default: '' }) text;
  @Prop({ type: Number, required: false }) max;
  @Prop({ type: Number, required: false }) min;
  handleChange(e) {
    let value = e.target.value;
    value = numberRegular(value);
    if (this.max && this.max < parseFloat(value)) {
      value = this.max;
    } else if (this.min && this.min > parseFloat(value)) {
      value = this.min;
    }
    this.$nextTick(() => {
      this.value = value;
      e.target.value = value;
      this.$emit('change', this.value);
    });
  }
}
</script>

<style lang="less" module>
.input {
  // font-size: 14px;
  // line-height: 24px;
  padding: 0;
  border: none;
  background: none;
  &:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
  }
}
</style>

<template>
  <div :class="$style.wrap">
    <LayoutContent>
      <template v-slot:header-left>
        <span :class="$style.title">{{
          $t('electricBoxMock.title.text')
        }}</span>
      </template>
      <div :class="$style.content" slot="content">
        <CardInfo :title="$t('electricBoxMock.title.text')">
          <mock-form @submit="handleMockFormSubmit" />
        </CardInfo>
        <CardInfo :title="$t('electricBoxMock.title.record')">
          <a-card :bordered="false" :class="$style.cardWrap">
            <a-input
              :class="$style.searchInput"
              @input="debounce"
              v-model="value"
              :placeholder="$t('electricBoxMock.placeholder.search')"
            >
              <a-icon slot="prefix" type="search" />
            </a-input>
            <a-Table
              :columns="columns"
              :dataSource="tableData"
              :pagination="pagination"
              :class="$style.table"
            >
            </a-Table>
          </a-card>
        </CardInfo>
      </div>
    </LayoutContent>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import dayjs from 'dayjs';
import CardInfo from './components/card-info';
import { Ellipsis } from '@triascloud/x-components';
import MockForm from './mock-form.vue';
import { getSimulation } from '@/services/iot-electric-box/simulation';
@Component({
  components: {
    LayoutContent,
    CardInfo,
    Ellipsis,
    MockForm,
  },
})
export default class ElectricBoxMock extends Vue {
  tableData = [];
  flag = null;
  value = '';
  pagination = {
    showQuickJumper: true,
    showSizeChanger: true,
    current: 1,
    total: 0,
    pageSize: 10,
    showTotal: total =>
      `${this.$t('electricBoxMock.message.total')}${total}${this.$t(
        'electricBoxMock.message.record',
      )}`,
    onShowSizeChange: this.sizeChange,
    onChange: this.pageChange,
  };
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('electricBoxMock.columns.index'),
        align: 'left',
        width: 100,
      },
      {
        dataIndex: 'projectName',
        customRender: text => (
          <Ellipsis rows={1} style="width:200px;">
            {text}
          </Ellipsis>
        ),
        title: this.$t('electricBoxMock.columns.projectName'),
        width: 200,
        align: 'left',
      },
      {
        dataIndex: 'buildingName',
        title: this.$t('electricBoxMock.columns.buildingName'),
        align: 'left',
      },
      {
        dataIndex: 'simulationTypeDescription',
        title: this.$t('electricBoxMock.columns.simulationType'),
        align: 'left',
        customRender: (val, row) => val || row.simulationType,
      },
      {
        dataIndex: 'equipment',
        title: this.$t('electricBoxMock.columns.equipemnt'),
        align: 'left',
      },
      {
        title: this.$t('electricBoxMock.columns.simulationValue'),
        dataIndex: 'simulationValue',
        customRender: (val, row) => this.formatUnit(row),
        align: 'left',
      },
      {
        dataIndex: 'operationTime',
        title: this.$t('electricBoxMock.columns.operationTime'),
        align: 'left',
        customRender: val =>
          val ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        dataIndex: 'operator',
        title: this.$t('electricBoxMock.columns.operator'),
        align: 'left',
      },
    ];
  }
  formatUnit(row) {
    const type = row.simulationType;
    let unit = `${row.simulationValue}`;
    if (row.simulationValue.includes('随机')) {
      return row.simulationValue;
    }
    if (type === 'CLIM_RISE' || type === 'CLIM_DROP') {
      return unit ? `${unit}层` : unit;
    }
    if (type === 'SEAT_TILT') {
      return `${unit} mm`;
    }
    if (type === 'SEAT_PULL') {
      return `${unit} 吨`;
    }
    if (type === 'SEAT_BATTERY_STATUS') {
      return `${unit == -1 ? '异常' : '正常'}`;
    }
    if (type === 'ELECTRIC_BOX_CURRENT') {
      return `${unit} A`;
    }
    if (type === 'SEAT_BATTERY_POWER') {
      return `${unit} %`;
    }
    return row.simulationValue;
  }

  mounted() {
    this.getRecords();
  }
  debounce() {
    this.flag && clearTimeout(this.flag);
    this.flag = setTimeout(this.search, 333);
  }
  search() {
    this.pagination.current = 1;
    this.getRecords();
  }
  sizeChange(page, size) {
    this.pagination.pageSize = size;
    this.getRecords();
  }
  pageChange(val) {
    this.pagination.current = val;
    this.getRecords();
  }
  async getRecords() {
    const value = this.value;
    const { current: pageNum, pageSize } = this.pagination;
    const { content, totalElements, totalPages } = await getSimulation({
      value,
      pageNum,
      pageSize,
    });
    this.tableData = content;
    this.pagination = {
      ...this.pagination,
      total: totalElements,
      current:
        this.pagination.current > totalPages
          ? totalPages
          : this.pagination.current,
    };
  }

  /** @name 模拟数据表单提交功能 */
  handleMockFormSubmit() {
    this.pagination.current = 1;
    this.getRecords();
  }
}
</script>

<style lang="less" module>
.wrap {
  flex: 1;
  display: flex;
  .title {
    font-size: 16px;
  }
}
.content {
  padding: 0 20px;
}
.searchInput {
  margin-bottom: 20px;
  input {
    width: 110px;
    transition: width 200ms linear;
    &:focus {
      width: 200px;
    }
  }
}
.table {
  :global(.ant-pagination.ant-table-pagination) {
    width: 100%;
    text-align: right;
  }
  :global(.ant-pagination-options-size-changer) {
    position: absolute;
    left: 60px;
    bottom: -46px;
    &::before {
      content: '每页显示';
      display: block;
      position: absolute;
      top: 50%;
      left: -60px;
      transform: translateY(-50%);
    }
  }
}
</style>

<template>
  <div :class="$style['draw-wrap']" ref="box">
    <div :class="$style['form-item']">
      <div :class="$style['form-label']">
        {{ $t('electricBoxMock.info.projectName') }}
      </div>
      <div>{{ options.projectName }}</div>
    </div>
    <div :class="$style['form-item']">
      <div :class="$style['form-label']">
        {{ $t('electricBoxMock.info.building') }}
      </div>
      <div>{{ options.buildingName }}</div>
    </div>
    <div>
      <div :class="$style['table-title']"></div>
      <a-table
        :bordered="true"
        :data-source="dataSource"
        :columns="columns"
        :pagination="false"
      >
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="name" slot-scope="text, record">
          <editable-cell
            :text="text"
            :max="90"
            :min="1"
            @change="onCellChange(record.key, 'name', $event)"
          />
        </template>
        <template slot="value" slot-scope="text, record">
          <editable-cell
            :text="text"
            @change="onCellChange(record.key, 'value', $event)"
          />
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="dataSource.length"
            :getPopupContainer="() => $refs.box"
            :title="$t('electricBoxMock.message.delMes')"
            @confirm="() => onDelete(record.key)"
          >
            <a-button type="link">
              <span class="TC tc-icon-iconfontshanchu"></span>
            </a-button>
          </a-popconfirm>
        </template>
      </a-table>
      <a-button
        :class="$style['add-btn']"
        type="link"
        icon="plus"
        @click="handleAdd"
      >
        {{ $t('electricBoxMock.button.add') }}
      </a-button>
      <div :class="$style['btn-wrap']">
        <a-button :class="$style.btn" @click="close">
          {{ $t('electricBoxMock.button.cancel') }}
        </a-button>
        <a-button :class="$style.btn" @click="handleMock">
          {{ $t('electricBoxMock.button.simulation') }}
        </a-button>
        <a-button :class="$style.btn" type="primary" @click="handleConfirm">
          {{ $t('electricBoxMock.button.confirm') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { message } from 'ant-design-vue';
import EditableCell from './editable-cell';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip/index';
function noop() {}
const SIMULATE_TYPE = 'SEAT_PULL';

@Component({
  components: {
    EditableCell,
  },
})
export default class DrawerInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) options;
  @Prop({ type: Function, default: noop }) submitSimulateApi;
  @Prop({ type: Function, default: noop }) close;

  created() {}

  dataSource = [
    {
      key: '0',
      name: '',
      value: '',
    },
  ];
  columns = [
    {
      title: this.$t('electricBoxMock.columnsInfo.index'),
      width: 80,
      scopedSlots: { customRender: 'index' },
    },
    {
      title: this.$t('electricBoxMock.columnsInfo.device'),
      dataIndex: 'name',
      width: 160,
      scopedSlots: { customRender: 'name' },
    },
    {
      title: this.$t('electricBoxMock.columnsInfo.ton'),
      dataIndex: 'value',
      width: 160,
      scopedSlots: { customRender: 'value' },
    },
    {
      title: this.$t('electricBoxMock.columnsInfo.operation'),
      dataIndex: 'operation',
      width: 80,
      scopedSlots: { customRender: 'operation' },
    },
  ];

  onCellChange(key, dataIndex, value) {
    const dataSource = [...this.dataSource];
    const target = dataSource.find(item => item.key === key);
    if (target) {
      target[dataIndex] = value;
      this.dataSource = dataSource;
    }
  }
  onDelete(key) {
    const dataSource = [...this.dataSource];
    this.dataSource = dataSource.filter(item => item.key !== key);
  }

  count = 1;
  handleAdd() {
    const { count, dataSource } = this;
    const newData = {
      key: count,
      name: '',
      value: '',
    };
    this.dataSource = [...dataSource, newData];
    this.count = count + 1;
  }
  validateData() {
    return new Promise(resolve => {
      this.dataSource.every(v => {
        if (!v.name) {
          resolve(false);
          return false;
        }
        if (!v.value) {
          resolve(false);
          return false;
        }
      });
      resolve(true);
    });
  }
  async handleConfirm() {
    const validateResult = await this.validateData();
    if (!validateResult) {
      message.warning(this.$t('electricBoxMock.info.message.value'));
    } else {
      const confirm = await createFormModal(
        () => (
          <Tip>
            <span slot="txt">{`您确定要对${this.options.projectName}的${this.options.buildingName}号楼进行机位载荷模拟吗？`}</span>
          </Tip>
        ),
        { title: '提示' },
      );
      if (!confirm) return;
      const data = this.dataSource
        .map(v => {
          return v.name && v.value
            ? {
                cId: v.name - 0,
                value: v.value - 0,
              }
            : null;
        })
        .filter(e => e);
      this.submitSimulateApi(
        {
          data,
          buildId: this.options.buildId,
          type: 'MANUAL',
          simulateType: SIMULATE_TYPE,
        },
        {
          success: () => {
            this.$emit('confirm', {
              data,
              projectName: this.options.projectName,
              buildingName: this.options.buildingName,
              type: SIMULATE_TYPE,
            });
            message.success(this.$t('electricBoxMock.info.message.pullSucess'));
          },
          error: () => {
            message.error(this.$t('electricBoxMock.info.message.pullError'));
          },
        },
      );
    }
  }
  async handleMock() {
    const confirm = await createFormModal(
      () => (
        <Tip>
          <span slot="txt">{`您确定要对${this.options.projectName}的${this.options.buildingName}号楼进行机位倾斜载荷吗？`}</span>
        </Tip>
      ),
      { title: '提示' },
    );
    if (!confirm) return;
    this.submitSimulateApi(
      {
        buildId: this.options.buildId,
        type: 'AUTO',
        simulateType: SIMULATE_TYPE,
      },
      {
        success: () => {
          this.$emit('confirm', {
            data: null,
            projectName: this.options.projectName,
            buildingName: this.options.buildingName,
            type: SIMULATE_TYPE,
            mock: true,
          });
          message.success(
            this.$t('electricBoxMock.info.message.pullOneSucess'),
          );
        },
        error: () => {
          message.error(this.$t('electricBoxMock.info.message.pullOneError'));
        },
      },
    );
  }
  handleCancel() {}
}
</script>

<style lang="less" module>
.draw-wrap {
  :global(.ant-popover-message) {
    text-align: center;
  }
  :global(.ant-popover-message i) {
    position: relative;
    display: inline-block;
    top: unset;
    margin-right: 10px;
  }
  :global(.ant-popover-message .ant-popover-message-title) {
    padding-left: 0;
    display: inline-block;
  }
  :global(.ant-table-bordered .ant-table-thead > tr > th) {
    border-color: var(--form-border);
  }
  .form-item {
    margin-bottom: 30px;
    font-size: 14px;
    color: var(--font-info);
  }
  .form-label {
    color: var(--font);
  }
  :global .ant-table-tbody > tr > td {
    border-bottom: 1px solid var(--form-border);
  }
  :global .ant-table-tbody > tr {
    border-bottom: 1px solid var(--form-border);
    // transition: background 0.3s;
    &:hover {
      // background: transparent;
      &:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: transparent;
      }
    }
  }
}

.add-btn {
  padding: 0;
  text-align: left;
}
.btn-wrap {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 20px;
  .btn {
    margin-right: 20px;
  }
}
</style>

<template>
  <div :class="$style['draw-wrap']">
    <div :class="$style['form-item']">
      <div :class="$style['form-label']">
        {{ $t('electricBoxMock.info.projectName') }}
      </div>
      <div>{{ options.projectName }}</div>
    </div>
    <div :class="$style['form-item']">
      <div :class="$style['form-label']">
        {{ $t('electricBoxMock.info.building') }}
      </div>
      <div>{{ options.buildingName }}</div>
    </div>
    <div :class="$style['form-item']">
      <div :class="$style['form-label']">
        {{ $t('electricBoxMock.info.device') }}
      </div>
      <div>{{ $t('electricBoxMock.info.box') }}</div>
    </div>
    <a-form-model ref="form" :rules="rules" :model="form">
      <a-form-model-item
        :label="$t('electricBoxMock.info.value')"
        prop="electricCurrent"
      >
        <a-input
          :value="form.electricCurrent"
          @input="handleInput"
          :placeholder="$t('electricBoxMock.info.placeholder.value')"
        />
      </a-form-model-item>
    </a-form-model>
    <div :class="$style['btn-wrap']">
      <a-button :class="$style.btn" @click="close">
        {{ $t('electricBoxMock.button.cancel') }}
      </a-button>
      <a-button :class="$style.btn" @click="handleMock">
        {{ $t('electricBoxMock.button.simulation') }}
      </a-button>
      <a-button :class="$style.btn" type="primary" @click="handleConfirm">
        {{ $t('electricBoxMock.button.confirm') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { message } from 'ant-design-vue';
import EditableCell from './editable-cell';
import { numberRegular } from '@/assets/js/util.js';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip/index';
function noop() {}
const SIMULATE_TYPE = 'ELECTRIC_BOX_CURRENT';

@Component({
  components: {
    EditableCell,
  },
})
export default class DrawerMainControlBoxCurrent extends Vue {
  @Prop({ type: Object, default: () => {} }) options;
  @Prop({ type: Function, default: noop }) submitSimulateApi;
  @Prop({ type: Function, default: noop }) close;

  form = {
    electricCurrent: '',
  };
  rules = {
    electricCurrent: {
      required: true,
      message: this.$t('electricBoxMock.info.placeholder.value'),
      trigger: 'blur',
    },
  };

  dataSource = [
    {
      key: '0',
      name: '',
      value: '',
    },
  ];

  handleInput(e) {
    let value = numberRegular(e.target.value);
    this.$nextTick(() => {
      this.form.electricCurrent = value;
      e.target.value = value;
    });
  }

  validateData() {
    return new Promise(resolve => {
      this.$refs.form.validate(valid => resolve(valid));
    });
  }
  async handleConfirm() {
    const validateResult = await this.validateData();
    if (!validateResult) {
      message.warning('请输入相关的模拟值');
    } else {
      const confirm = await createFormModal(
        () => (
          <Tip>
            <span slot="txt">{`您确定要对${this.options.projectName}的${this.options.buildingName}号楼进行总控箱电流模拟吗？`}</span>
          </Tip>
        ),
        { title: '提示' },
      );
      if (!confirm) return;
      const data = {
        value: parseFloat(this.form.electricCurrent),
      };
      this.submitSimulateApi(
        {
          data,
          buildId: this.options.buildId,
          type: 'MANUAL',
          simulateType: SIMULATE_TYPE,
        },
        {
          success: () => {
            this.$emit('confirm', {
              data: `${data.value}`,
              mainbox: true,
              projectName: this.options.projectName,
              buildingName: this.options.buildingName,
              type: SIMULATE_TYPE,
            });
            message.success('总控箱电流模拟提交成功');
          },
          error: () => {
            message.error('总控箱电流模拟提交失败');
          },
        },
      );
    }
  }
  async handleMock() {
    const confirm = await createFormModal(
      () => (
        <Tip>
          <span slot="txt">{`您确定要对${this.options.projectName}的${this.options.buildingName}号楼进行总控箱电流模拟吗？`}</span>
        </Tip>
      ),
      { title: '提示' },
    );
    if (!confirm) return;
    this.submitSimulateApi(
      {
        buildId: this.options.buildId,
        type: 'AUTO',
        simulateType: SIMULATE_TYPE,
      },
      {
        success: () => {
          this.$emit('confirm', {
            data: null,
            projectName: this.options.projectName,
            buildingName: this.options.buildingName,
            type: SIMULATE_TYPE,
            mock: true,
          });
          message.success('总控箱电流一键模拟提交成功');
        },
        error: () => {
          message.error('总控箱电流一键模拟提交失败');
        },
      },
    );
  }
  handleCancel() {}
}
</script>

<style lang="less" module>
.draw-wrap {
  :global(.ant-popover-message) {
    text-align: center;
  }
  :global(.ant-popover-message i) {
    position: relative;
    display: inline-block;
    top: unset;
    margin-right: 10px;
  }
  :global(.ant-popover-message .ant-popover-message-title) {
    padding-left: 0;
    display: inline-block;
  }
  .form-item {
    margin-bottom: 30px;
    font-size: 14px;
    color: var(--font-info);
  }
  .form-label {
    color: var(--font);
  }
  :global .ant-table-tbody > tr > td {
    border-bottom: 1px solid var(--form-border);
  }
  :global .ant-table-tbody > tr {
    border-bottom: 1px solid var(--form-border);
    &:hover {
      &:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: transparent;
      }
    }
  }
}

.btn-wrap {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 20px;
  .btn {
    margin-right: 20px;
  }
}
</style>

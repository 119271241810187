<template>
  <div
    :class="$style.btnWrap"
    :style="{ 'background-color': item.backgroundColor }"
    @click="item.clickHandle"
  >
    <span :class="$style.text">{{ item.text }}</span>
    <img :class="$style.imgUrl" :src="item.imgUrl" alt="" />
    <div v-if="item.disabled" :class="$style.mask"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BtnInfo extends Vue {
  @Prop({ type: Object, default: {} }) item;
}
</script>

<style lang="less" module>
.btnWrap {
  width: 13%;
  margin-top: 20px;
  background: #01b893;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  // margin-right: 20px;
  position: relative;
  cursor: pointer;
  .text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    color: #ffffff;
  }
  .icon {
    width: 40px;
  }
  .mask {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  &:last-child {
    margin-right: 0;
  }
}
</style>

import request from '../request';

const serviceName = '/iot-electric-box/simulation';

/**
 * @name 查询安监终端的模拟数据
 */
export function getSimulation(data) {
  return request(`${serviceName}/page`, {
    body: data,
  });
}

/** @name 添加安监终端的模拟数据 */
export function addSimulation(data) {
  return request(`${serviceName}/add`, {
    method: 'POST',
    body: data,
  });
}
